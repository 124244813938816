import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

function AddEditPurchaseHistory() {
    const { id } = useParams();
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientsList, setClientsList] = useState([]);

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    let config = {}

    useEffect(() => {
        if (id) {
            async function fetchPurchaseHistory() {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-purchase-history/${id}/`, config);
                console.log(data.date.split('T')[1], "<><><><>")
                setDate(data.date.split('T')[0]);
                const timePart = data.date.split('T')[1].split(':');
                const formattedTime = `${timePart[0]}:${timePart[1]}`;
                setTime(formattedTime);
                setTotalAmount(data.total_amount);
                setClientId(data.client_id.id);
            }
            if (userInfo) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.access}`,
                    },
                };
                fetchPurchaseHistory()
            }
        }

        async function fetchClientsList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-client-list/`, config);
            setClientsList(data);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchClientsList()
        }
    }, []);

    const handleSubmit = async (e) => {
        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        e.preventDefault();
        const purchaseData = {
            client_id: clientId,
            date_time: `${date}T${time}:00`,
            total_amount: totalAmount,
        };

        try {
            if (id) {
                await axios.post(`${apiUrl}/api/merchant/update-purchase-history/${id}/`, purchaseData, config);
            } else {
                await axios.post(`${apiUrl}/api/merchant/add-purchase-history/`, purchaseData, config);
            }
            navigate('/purchase-history/');
        } catch (error) {
            console.error('Error saving purchase history:', error);
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <h1>{id ? 'Edit Purchase History' : 'Add Purchase History'}</h1>
                    <br />
                    <Form onSubmit={handleSubmit}>
                        <Form.Select required value={clientId} onChange={(e) => setClientId(e.target.value)}>
                            <option value="" disabled>Select a client</option>
                            {clientsList.map(client => (
                                <option key={client.id} value={client.id}>
                                    {client.name}
                                </option>
                            ))}
                        </Form.Select>
                        <br />
                        <Form.Control
                            required
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                        <br />
                        <Form.Control
                            required
                            type="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                        <br />
                        <Form.Control
                            required
                            type="number"
                            placeholder="Total Amount"
                            value={totalAmount}
                            onChange={(e) => setTotalAmount(e.target.value)}
                        />
                        <br />
                        <Button type="submit" variant="success">
                            {id ? 'Update' : 'Add'} Purchase
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default AddEditPurchaseHistory;
