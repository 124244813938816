import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function AddEditItems() {
    const { id } = useParams();  // Get the item ID from the URL params
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [businesses, setBusinesses] = useState('');

    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    // Axios config with authorization token
    let config = {}

    // List of businesses for dropdown
    const [businessesList, setBusinessesList] = useState([]);

    // Fetch data when component mounts
    useEffect(() => {
        if (id) {
            async function fetchItem() {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-item/${id}/`, config);
                setName(data.name);
                setPrice(data.price);
                setBusinesses(data.businesses.id);
            }
            if (userInfo) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.access}`,
                    },
                };
    
                fetchItem()
    
            }
        }

        async function fetchBusinessesList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-business-list/`, config);
            setBusinessesList(data);
        }

        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };

            fetchBusinessesList()

        }
    }, []);

    // Handle the change in the selected business
    const handleSelectChange = (e) => {
        setBusinesses(e.target.value);
    };

    // Handle form submission
    const submitHandler = async (e) => {
        e.preventDefault();

        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };

        if (id) {
            // Update existing item
            try {
                const { data } = await axios.post(
                    `${apiUrl}/api/merchant/update-item/${id}/`,
                    { name, price, businesses },
                    config
                );
                navigate('/items/');
            } catch (error) {
                console.error('Error updating item:', error);
            }
        } else {
            // Add new item
            try {
                const { data } = await axios.post(
                    `${apiUrl}/api/merchant/add-item/`,
                    { name, price, businesses },
                    config
                );
                navigate('/items/');
            } catch (error) {
                console.error('Error adding item:', error);
            }
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <div className='page-title-and-bnt'>
                        {id ? <h1>Edit Item</h1> : <h1>Add Item</h1>}
                    </div>
                    <br></br>

                    <Form onSubmit={submitHandler}>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Control
                            required
                            type='number'
                            placeholder='Price'
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Select
                            required
                            value={businesses}
                            onChange={handleSelectChange}
                        >
                            <option value="" disabled>Select a business</option>
                            {businessesList.map((businessObj) => (
                                <option key={businessObj.id} value={businessObj.id}>
                                    {businessObj.business_name}
                                </option>
                            ))}
                        </Form.Select>
                        <br></br>
                        <Button type='submit' variant='success'>Submit</Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default AddEditItems;
