import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { color } from 'chart.js/helpers';
import { Link } from 'react-router-dom'

function Navbar({setSidebarVisible, isSidebarVisible}) {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch()

    const navigate = useNavigate();
    const logoutHandler = () => {
        dispatch(logout())
        navigate('/')
    }

    const handleSideToggle = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    useEffect(() => {
        if (!userInfo) {
            navigate('/')
        }
    }, [userInfo])

    return (
        <div className="navbar-custom">
            <div className="topbar container-fluid">
                <div className="d-flex align-items-center gap-lg-2 gap-1">

                    <div className="logo-topbar">
                        <Link to={'/dashboard/'}>
                            <a className="logo-light">
                                <span className="logo-lg">
                                    <h3 style={{ color: 'white' }} >KLERK</h3>
                                </span>
                            </a>
                        </Link>
                    </div>
                    <i className='uil uil-bars side-bar-toggel' onClick={handleSideToggle}></i>
                </div>

                <ul className="topbar-menu d-flex align-items-center gap-3">

                    <li className="dropdown">
                        <div className="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown"
                            role="button" aria-haspopup="false" aria-expanded="false" onClick={logoutHandler}>
                                <i className=' uil-sign-out-alt'></i>
                            <span className="d-lg-flex flex-column gap-1 d-none">
                                <h5 className="my-0" style={{ color: 'white' }}>Logout</h5>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar

