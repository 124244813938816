import React, { useState, useEffect } from 'react'

import { Container, Form, Button } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import { useParams } from 'react-router-dom';

import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

function AddEditBusiness({ match }) {


    const { id } = useParams();
    const [businessName, setBusinessName] = useState('')
    const [contact, setContact] = useState('')

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    let config = {}

    useEffect(() => {
        if (id) {
            async function fetchBusinessesList() {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-business/${id}/`,
                    config)
                setBusinessName(data.business_name)
                setContact(data.contact_info)
            }

            if (userInfo) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.access}`,
                    },
                };
    
                fetchBusinessesList()
            }
        }
    }, [])


    const submitHandler = async (e) => {
        e.preventDefault()

        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        
        if (id) {
            try {
                const { data } = await axios.post(
                    `${apiUrl}/api/merchant/update-business/${id}/`,
                    { 'businessName': businessName, 'contact': contact },
                    config
                );
                navigate('/businesses/')
            } catch (error) {
                console.error('Error updating business:', error);
            }
        } else {
            try {
                const { data } = await axios.post(
                    `${apiUrl}/api/merchant/add-business/`,
                    { 'businessName': businessName, 'contact': contact },
                    config
                );
                navigate('/businesses/')
            } catch (error) {
                console.error('Error adding business:', error);
            }
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <div className='page-title-and-bnt'>
                        {id ?
                            <h1>Edit Businesses</h1>
                            :
                            <h1>Add Businesses</h1>
                        }
                    </div>
                    <br></br>

                    <Form onSubmit={submitHandler}>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Business Name'
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Contact Info'
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Button type='submit' variant='success'>Submit</Button>
                    </Form>

                </div>
            </div>
        </div>
    )
}

export default AddEditBusiness
