import React from 'react';
import Chart from 'react-apexcharts';

function IncreasedMarginChart() {
    // Test data for product margins
    const products = ['Product A', 'Product B', 'Product C', 'Product D'];
    const margins = [40, 30, 20, 10]; // Margins in percentage for each product

    // ApexChart configuration for Increased Margin
    const chartOptions = {
        chart: {
            id: 'increased-margin-chart',
            type: 'bar',
            height: 350,
            toolbar: {
                show: false, // Disable toolbar (no download option)
            },
        },
        xaxis: {
            categories: products, // Product names on the x-axis
            title: {
                text: 'Products',
            },
        },
        yaxis: {
            title: {
                text: 'Margin Contribution (%)',
            },
        },
        title: {
            text: 'Increased Margin by Product',
            align: 'center',
            style: {
                fontSize: '20px',
            },
        },
        colors: ['#FF5733'], // Use a distinctive color for the bars
    };

    const chartSeries = [
        {
            name: 'Margin Contribution',
            data: margins, // Margins in percentage
        },
    ];

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid"></div>
                <br></br>
                <div style={{ padding: '20px' }}>
                    <h2>Increased Margin (High Value Products)</h2>
                    <br></br>
                    <Chart options={chartOptions} series={chartSeries} type="bar" height={350} />
                </div>
            </div>
        </div>
    );
}

export default IncreasedMarginChart;
