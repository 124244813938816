import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

function AddEditItemsPurchased() {
    const { id } = useParams();
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [purchaseHistoryId, setPurchaseHistoryId] = useState('');
    const [purchaseHistoryList, setPurchaseHistoryList] = useState([]);

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    let config = {}

    useEffect(() => {
        if (id) {
            async function fetchItemPurchased() {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-item-purchased/${id}/`, config);
                setItemName(data.item_name);
                setQuantity(data.quantity);
                setPrice(data.price);
                setPurchaseHistoryId(data.purchase_history);
            }
            if (userInfo) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.access}`,
                    },
                };
    
                fetchItemPurchased()
    
            }
        }

        async function fetchPurchaseHistoryList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-purchase-history-list/`, config);
            setPurchaseHistoryList(data);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };

            fetchPurchaseHistoryList()

        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const itemData = {
            purchase_history: purchaseHistoryId,
            item_name: itemName,
            quantity,
            price,
        };

        try {
            if (id) {
                await axios.post(`${apiUrl}/api/merchant/update-item-purchased/${id}/`, itemData, config);
            } else {
                await axios.post(`${apiUrl}/api/merchant/add-item-purchased/`, itemData, config);
            }
            navigate('/items-purchased');
        } catch (error) {
            console.error('Error saving item purchased:', error);
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
            <br />
            <h1>{id ? 'Edit Item Purchased' : 'Add Item Purchased'}</h1>
            <br />
            <Form onSubmit={handleSubmit}>
                <Form.Select required value={purchaseHistoryId} onChange={(e) => setPurchaseHistoryId(e.target.value)}>
                    <option value="" disabled>Select Purchase History</option>
                    {purchaseHistoryList.map(purchase => (
                        <option key={purchase.id} value={purchase.id}>
                            {purchase.id} - {purchase.date}
                        </option>
                    ))}
                </Form.Select>
                <br />
                <Form.Control
                    required
                    type="text"
                    placeholder="Item Name"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                />
                <br />
                <Form.Control
                    required
                    type="number"
                    placeholder="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
                <br />
                <Form.Control
                    required
                    type="number"
                    placeholder="Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
                <br />
                <Button type="submit" variant="success">
                    {id ? 'Update' : 'Add'} Item Purchased
                </Button>
            </Form>
        </div>
</div>
</div>
    );
}

export default AddEditItemsPurchased;
