import React, { useState, useEffect } from 'react'

import Table from 'react-bootstrap/Table';

import { Container } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import Button from 'react-bootstrap/Button';

function UsersList() {

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    const [usersList, setUsersList] = useState([])

    useEffect(() => {

        async function fetchUsersList() {
            const { data } = await axios.get(`${apiUrl}/api/user/users-list/`,
                config)
            setUsersList(data)
        }

        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };

            fetchUsersList()

        }

    }, [])

    const deactivateHandler = async (id) => {

        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };

        try {
            const { data } = await axios.post(
                `${apiUrl}/api/user/deactivate-user/${id}/`,
                {},
                config
            );
            setUsersList(data);
        } catch (error) {
            console.error('Error activating user:', error);
        }

    };

    const deleteUserHandler = async (id) => {

        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };

        try {
            const { data } = await axios.delete(
                `${apiUrl}/api/user/delete-user/${id}/`,
                config
            );
            setUsersList(data);
        } catch (error) {
            console.error('Error activating user:', error);
        }

    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <h1>Users</h1>
                    <br></br>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>

                            {usersList.map(user => (
                                <tr>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <div className='users-list-btn'>
                                            <Button
                                                variant="primary"
                                                onClick={(e) => deactivateHandler(user.id)}
                                            >Deactivate
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={(e) => deleteUserHandler(user.id)}
                                            >Delete
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default UsersList
