import React, { useState } from 'react';

import { Link, redirect } from 'react-router-dom'

import Loader from '../components/Loader'

const ForgotPassword = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            // Make an API request to your Django backend to trigger the reset email
            const response = await fetch(`${apiUrl}/api/user/password-reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage('Password reset email sent! Please check your inbox.');
            } else {
                setMessage('Failed to send password reset email. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }

        setLoading(false)
    };

    return (
        <>
            {loading ? <Loader /> :
                <div className="custom-container" style={{ position: 'absolute', width: '100%', left: '0px' }}>
                    <form className="left" onSubmit={handleSubmit}>
                        <h1>Forget Password</h1>
                        {message && <p className='error'>{message}</p>}

                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <div className="submit-btn-wrapper">
                            <button type="submit">Send Reset Link</button>
                        </div>
                        <Link to={'/'}>
                            <a className='other-page-link'>Login</a>
                        </Link>
                    </form>
                    <div className="right">
                        <h1>Back to login page</h1>
                        <div className="sign-up-wrapper">
                            <Link to={'/'}>
                                <button>Login</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ForgotPassword;
