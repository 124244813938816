import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

function SideBar({isSidebarVisible}) {

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const navigate = useNavigate();
    useEffect(() => {
        if (!userInfo) {
            navigate('/')
        }
    }, [])

    return (
        <div className={isSidebarVisible ? 'leftside-menu' : 'disable-leftside-menu'}>

            <div className="h-100" id="leftside-menu-container" data-simplebar>
                <div className="leftbar-user">
                    <a href="pages-profile.html">

                    </a>
                </div>
                <ul className="side-nav">

                    <li className="side-nav-title">Navigation</li>


                    {userInfo && userInfo.is_admin ?
                        <>

                            <li className="side-nav-item">
                                <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                                    <i className="uil-calender"></i>
                                    <span>
                                        <NavDropdown title={<span >Users</span>} id='Profile'>

                                            <LinkContainer to={'/inactive-users/'}>
                                                <NavDropdown.Item>Inactive Users</NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to={'/users-list/'}>
                                                <NavDropdown.Item>Users List</NavDropdown.Item>
                                            </LinkContainer>

                                        </NavDropdown>
                                    </span>
                                </a>
                            </li>
                        </>
                        :
                        <>
                        </>
                    }

                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <NavDropdown title={<span >Profile</span>} id='Profile'>

                                    {userInfo && userInfo.is_admin ?

                                        <LinkContainer to={'/businesses/'}>
                                            <NavDropdown.Item>Businesses</NavDropdown.Item>
                                        </LinkContainer>
                                        :
                                        <></>
                                    }
                                    <LinkContainer to={'/clients/'}>
                                        <NavDropdown.Item>Clients</NavDropdown.Item>
                                    </LinkContainer>

                                </NavDropdown>
                            </span>
                        </a>
                    </li>

                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <NavDropdown title={<span >Items</span>} id='Profile'>

                                    <LinkContainer to={'/items/'}>
                                        <NavDropdown.Item>Items</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to={'/items-purchased/'}>
                                        <NavDropdown.Item>Items Purchased</NavDropdown.Item>
                                    </LinkContainer>

                                </NavDropdown>
                            </span>
                        </a>
                    </li>

                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <Link to={'/purchase-history/'}>
                                    Purchase History
                                </Link>
                            </span>
                        </a>
                    </li>

                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <Link to={'/receipts-history/'}>
                                    <>Receipts History</>
                                </Link>
                            </span>
                        </a>
                    </li>

                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <Link to={'/customer-churn-rate-graph/'}>
                                    <>Churn Rate</>
                                </Link>
                            </span>
                        </a>
                    </li>


                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <Link to={'/items-sold-details/'}>
                                    <>Items overview</>
                                </Link>
                            </span>
                        </a>
                    </li>

                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <Link to={'/NPS/'}>
                                    <> Net Promoter Score</>
                                </Link>
                            </span>
                        </a>
                    </li>

                    <li className="side-nav-item">
                        <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="uil-calender"></i>
                            <span>
                                <Link to={'/increasedMarginChart/'}>
                                    <> Increased Margin Chart</>
                                </Link>
                            </span>
                        </a>
                    </li>

                    {userInfo && userInfo.is_admin ?
                        <>
                            <li className="side-nav-item">
                                <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                                    <i className="uil-calender"></i>
                                    <span>
                                        <Link to={'/repeat-purchase-rate-graph/'}>
                                            <>Repeat Purchase</>
                                        </Link>
                                    </span>
                                </a>
                            </li>

                        </>
                        :
                        <li className="side-nav-item">
                            <a className="side-nav-link" style={{ display: 'flex', alignItems: 'center' }}>
                                <i className="uil-calender"></i>
                                <span>
                                    <Link to={'/revenue-graph/'}>
                                        <>Revenue Graph</>
                                    </Link>
                                </span>
                            </a>
                        </li>
                    }


                </ul>
            </div>
        </div>
    )
}

export default SideBar
