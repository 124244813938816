import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

function AddEditReceiptsHistory() {
    const { id } = useParams();
    const [accountNumber, setAccountNumber] = useState('');
    const [company, setCompany] = useState('');
    const [transactionDate, setTransactionDate] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [plan, setPlan] = useState('');

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    useEffect(() => {
        if (id) {
            async function fetchReceiptHistory() {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-receipt-history/${id}/`, config);
                setAccountNumber(data.account_number);
                setCompany(data.company);
                setTransactionDate(data.transaction_date);
                setPaymentAmount(data.payment_amount);
                setPaymentType(data.payment_type);
                setPlan(data.plan);
            }
            if (userInfo) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.access}`,
                    },
                };
                fetchReceiptHistory()
            }
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };

        const receiptData = {
            account_number: accountNumber,
            company,
            transaction_date: transactionDate,
            payment_amount: paymentAmount,
            payment_type: paymentType,
            plan,
        };

        try {
            if (id) {
                await axios.post(`${apiUrl}/api/merchant/update-receipt-history/${id}/`, receiptData, config);
            } else {
                await axios.post(`${apiUrl}/api/merchant/add-receipt-history/`, receiptData, config);
            }
            navigate('/receipts-history');
        } catch (error) {
            console.error('Error saving receipt history:', error);
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
            <br />
            <h1>{id ? 'Edit Receipt History' : 'Add Receipt History'}</h1>
            <br />
            <Form onSubmit={handleSubmit}>
                <Form.Control
                    required
                    type="text"
                    placeholder="Account Number"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                />
                <br />
                <Form.Control
                    required
                    type="text"
                    placeholder="Company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />
                <br />
                <Form.Control
                    required
                    type="date"
                    placeholder="Transaction Date"
                    value={transactionDate}
                    onChange={(e) => setTransactionDate(e.target.value)}
                />
                <br />
                <Form.Control
                    required
                    type="number"
                    placeholder="Payment Amount"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(e.target.value)}
                />
                <br />
                <Form.Control
                    required
                    type="text"
                    placeholder="Payment Type"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                />
                <br />
                <Form.Control
                    required
                    type="text"
                    placeholder="Plan"
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                />
                <br />
                <Button type="submit" variant="success">
                    {id ? 'Update' : 'Add'} Receipt History
                </Button>
            </Form>
        </div>
</div>
</div>
    );
}

export default AddEditReceiptsHistory;
