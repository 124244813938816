import React, { useState, useEffect } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ItemsPurchased() {
    const [itemsList, setItemsList] = useState([]);
    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {};

    useEffect(() => {
        async function fetchItemsList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-items-purchased-list/`, config);
            setItemsList(data);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchItemsList()
        }
    }, [config]);

    const deleteItemPurchasedHandler = async (id) => {
        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        try {
            await axios.delete(`${apiUrl}/api/merchant/delete-item-purchased/${id}/`, config);
            setItemsList(prevList => prevList.filter(item => item.id !== id));
        } catch (error) {
            console.error('Error deleting item purchased:', error);
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
            <br />
            <div className="page-title-and-bnt">
                <h1>Items Purchased</h1>
                {userInfo && userInfo.is_admin && (
                    <LinkContainer to="/add-item-purchased/">
                        <Button variant="success">Add Item Purchased</Button>
                    </LinkContainer>
                )}
            </div>
            <br />
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Purchase History ID</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        {userInfo && userInfo.is_admin && <th>Options</th>}
                    </tr>
                </thead>
                <tbody>
                    {itemsList.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.purchase_history.id}</td>
                            <td>{item.item_name}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                            {userInfo && userInfo.is_admin && (
                                <td>
                                    <div className='users-list-btn'>
                                        <LinkContainer to={`/edit-item-purchased/${item.id}/`}>
                                            <Button variant="info">Edit</Button>
                                        </LinkContainer>
                                        <Button variant="danger" onClick={() => deleteItemPurchasedHandler(item.id)}>
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
</div>
</div>
    );
}

export default ItemsPurchased;
