import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function RepeatPurchaseRateGraph() {
    const [repeatPurchaseRate, setRepeatPurchaseRate] = useState(0);
    const [repeatClients, setRepeatClients] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    useEffect(() => {
        async function fetchRepeatPurchaseRate() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-repeat-purchase-rate/`, config);
            setRepeatPurchaseRate(data.repeat_purchase_rate);
            setRepeatClients(data.repeat_clients);
            setTotalClients(data.total_clients);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchRepeatPurchaseRate()
        }
    }, [config]);

    const data = {
        labels: ['Repeat Clients', 'Single Purchase Clients'],
        datasets: [
            {
                label: 'Repeat Purchase Rate',
                data: [repeatClients, totalClients - repeatClients],
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384'],
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,  // This allows custom height and width
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <h1>Repeat Purchase Rate</h1>
                    <br></br>
                    <div style={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Doughnut data={data} options={options} height={300} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RepeatPurchaseRateGraph;
