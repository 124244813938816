import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

function Items() {
    const dispatch = useDispatch();

    // Get user info from the Redux store
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    // Axios config for authorization
    let config = {}

    // State to hold the list of items
    const [itemsList, setItemsList] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    // Fetch items list on component mount
    useEffect(() => {
        async function fetchItemsList() {
            try {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-item-list/`, config);
                setItemsList(data);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchItemsList()
        }
    }, [config]);

    // Function to delete an item
    const deleteItemHandler = async (id) => {
        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        try {
            const { data } = await axios.delete(
                `${apiUrl}/api/merchant/delete-item/${id}/`,
                config
            );
            setItemsList(data);  // Update the item list after deletion
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
            <br></br>
            <div className='page-title-and-bnt'>
                <h1>Items</h1>
                {userInfo && userInfo.is_admin ? (
                    <LinkContainer to={`/add-item/`}>
                        <Button variant="success">Add+</Button>
                    </LinkContainer>
                ) : (
                    <></>
                )}
            </div>
            <br></br>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Business</th>
                        {userInfo && userInfo.is_admin ? <th>Options</th> : <></>}
                    </tr>
                </thead>
                <tbody>
                    {itemsList.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                            <td>{item.businesses.business_name}</td>
                            {userInfo && userInfo.is_admin ? (
                                <td>
                                    <div className='users-list-btn'>
                                        <LinkContainer to={`/edit-item/${item.id}/`}>
                                            <Button variant="info">Update</Button>
                                        </LinkContainer>
                                        <Button
                                            variant="danger"
                                            onClick={() => deleteItemHandler(item.id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            ) : (
                                <></>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
</div>
</div>
    );
}

export default Items;
