import React, { useState, useEffect } from 'react'

import Table from 'react-bootstrap/Table';

import axios from 'axios'

import Button from 'react-bootstrap/Button';

import { useDispatch, useSelector } from 'react-redux'

import Loader from '../../components/Loader'


function NewUsers() {

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const apiUrl = process.env.REACT_APP_API_URL;

  let config = {}

  const [inactiveUserList, setInactiveUserList] = useState([])

  useEffect(() => {

    async function fetchProducts() {
      const { data } = await axios.get(`${apiUrl}/api/user/get-inactive-user/`,
        config)
      setInactiveUserList(data)
    }

    if (userInfo) {
      config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      fetchProducts()

    }

  }, [])

  const clickHandler = async (id) => {
    setLoading(true)
    config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    try {
      const { data } = await axios.post(
        `${apiUrl}/api/user/activate-user/${id}/`,
        {},
        config
      );
      setInactiveUserList(data);
    } catch (error) {
      console.error('Error activating user:', error);
    }

    setLoading(false)

  };

  return (
    <>

      {loading ? <Loader /> :
        <div className="content-page">
          <div className="content">

            <div className="container-fluid">
              <br></br>
              <h1>Inactive Users</h1>
              <br></br>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {inactiveUserList.map(user => (
                    <tr>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>
                        <Button
                          variant="info"
                          onClick={(e) => clickHandler(user.id)}
                        >Activate
                        </Button>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </Table>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default NewUsers
