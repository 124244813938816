import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // to get the token from the URL
import Loader from '../components/Loader'
import { Link, redirect } from 'react-router-dom'

const ResetPassword = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)

    // Get the token and uid from the URL query string
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const uid = query.get('uid'); // Ensure uid is being passed in the URL as well

    console.log(token)
    console.log(uid)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match');
            setLoading(false)
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/user/password-reset-confirm/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uid, token, new_password: newPassword }), // Send both uid and token
            });

            if (response.ok) {
                setMessage('Password has been reset successfully!');
            } else {
                setMessage('Failed to reset password. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }
        setLoading(false)
    };

    return (

        <>
            {loading ? <Loader /> :
                <div className="custom-container" style={{ position: 'absolute', width: '100%', left: '0px' }}>
                    <form className="left" onSubmit={handleSubmit}>
                        <h1>Reset Password</h1>
                        {message && <p className='error'>{message}</p>}

                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />

                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />

                        <div className="submit-btn-wrapper">
                            <button type="submit">Reset Password</button>
                        </div>
                        <Link to={'/'}>
                            <a className='other-page-link'>Login</a>
                        </Link>
                    </form>
                    <div className="right">
                        <h1>Back to login page</h1>
                        <div className="sign-up-wrapper">
                            <Link to={'/'}>
                                <button>Login</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ResetPassword;
