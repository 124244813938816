export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const GET_NEW_USERS_LIST_REQUEST = 'GET_NEW_USERS_LIST_REQUEST'
export const GET_NEW_USERS_LIST_SUCCESS = 'GET_NEW_USERS_LIST_SUCCESS'
export const GET_NEW_USERS_LIST_FAIL = 'GET_NEW_USERS_LIST_FAIL'

export const NEW_USER_DELETE_REQUEST = 'NEW_USER_DELETE_REQUEST'
export const NEW_USER_DELETE_SUCCESS = 'NEW_USER_DELETE_SUCCESS'
export const NEW_USER_DELETE_FAIL = 'NEW_USER_DELETE_FAIL'