import React, { useState, useEffect } from 'react'

import Chart from 'react-apexcharts';

function Dashboard() {

    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: 'high-performing-product',
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        colors: ['#727cf5', '#91a6bd40'],
        xaxis: {
            categories: ['Product A', 'Product B', 'Product C', 'Product D'],
        },
        dataLabels: {
            enabled: false,
        },
        title: {
            text: 'High Performing Products',
            align: 'center',
            style: {
                fontSize: '20px',
            },
        },
    });

    // Sample data for the chart
    const [chartSeries, setChartSeries] = useState([
        {
            name: 'Sales',
            data: [44, 55, 41, 67],
        },
        {
            name: 'Revenue',
            data: [53, 32, 33, 52],
        },
    ]);

    return (
        <div className="content-page">
            <div className="content">

                <div className="container-fluid">
                    <br></br>
                    <h1>Dashboard</h1>
                    <br></br>
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="card widget-flat">
                                        <div className="card-body">
                                            <div className="float-end">
                                                <i className="mdi mdi-account-multiple widget-icon"></i>
                                            </div>
                                            <h5 className="text-muted fw-normal mt-0" title="Number of Customers">Customers</h5>
                                            <h3 className="mt-3 mb-3">36,254</h3>
                                            <p className="mb-0 text-muted">
                                                <span className="text-success me-2"><i className="mdi mdi-arrow-up-bold"></i> 5.27%</span>
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="card widget-flat">
                                        <div className="card-body">
                                            <div className="float-end">
                                                <i className="mdi mdi-cart-plus widget-icon bg-success-lighten text-success"></i>
                                            </div>
                                            <h5 className="text-muted fw-normal mt-0" title="Number of Orders">Orders</h5>
                                            <h3 className="mt-3 mb-3">5,543</h3>
                                            <p className="mb-0 text-muted">
                                                <span className="text-danger me-2"><i className="mdi mdi-arrow-down-bold"></i> 1.08%</span>
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="card widget-flat">
                                        <div className="card-body">
                                            <div className="float-end">
                                                <i className="mdi mdi-currency-usd widget-icon bg-success-lighten text-success"></i>
                                            </div>
                                            <h5 className="text-muted fw-normal mt-0" title="Average Revenue">Revenue</h5>
                                            <h3 className="mt-3 mb-3">$6,254</h3>
                                            <p className="mb-0 text-muted">
                                                <span className="text-danger me-2"><i className="mdi mdi-arrow-down-bold"></i> 7.00%</span>
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="card widget-flat">
                                        <div className="card-body">
                                            <div className="float-end">
                                                <i className="mdi mdi-pulse widget-icon"></i>
                                            </div>
                                            <h5 className="text-muted fw-normal mt-0" title="Growth">Growth</h5>
                                            <h3 className="mt-3 mb-3">+ 30.56%</h3>
                                            <p className="mb-0 text-muted">
                                                <span className="text-success me-2"><i className="mdi mdi-arrow-up-bold"></i> 4.87%</span>
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-7 col-lg-6">
                            <div className="card card-h-100">
                                <br></br>
                                <div className="card-body pt-0">
                                    <div id="high-performing-product" className="apex-charts">
                                        <Chart options={chartOptions} series={chartSeries} type="bar" height={350} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
