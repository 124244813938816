import React, { useState, useEffect } from 'react'

import { Link, redirect } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import Loader from '../components/Loader'

import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';


import { register } from '../actions/userActions'

function Signup() {

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [registrationError, setRegistrationError] = useState('')

    const dispatch = useDispatch()

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo, success } = userRegister

    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo) {
            navigate('/dashboard/')
        }
    }, [userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setRegistrationError("Password does not match!")
        } else {
            dispatch(register(username, email, password))
        }
    }

    useEffect(() => {
        if (success) {
            setUsername('')
            setEmail('')
            setPassword('')
            setConfirmPassword('')
            setRegistrationError('')
        }
    }, [success])

    return (
        <>
            {loading ? <Loader /> :
                <div className="custom-container" style={{ position: 'absolute', width: '100%', left: '0px' }}>
                    <form className="left" onSubmit={submitHandler}>
                        <h1>Sign-up</h1>
                        {
                            error && <p className='error'>{error}</p>
                        }
                        {
                            registrationError && <p className='error'>{registrationError}</p>
                        }
                        {
                            success && <p className='error'>{success.message}</p>
                        }

                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <div className="submit-btn-wrapper">
                            <button type='submit'>Sign-up</button>
                        </div>
                        <Link to={'/'}>
                            <a className='other-page-link'>Login</a>
                        </Link>
                        <div className="social-login">
                            <p>Or sign up with</p>
                            <div className="social-login-btns">
                                <button className="social-button fb">f</button>
                                <button className="social-button email">@</button>
                                <button className="social-button twitter">t</button>
                            </div>
                        </div>
                    </form>
                    <div className="right">
                        <h1>Welcome Back!</h1>
                        <p>Already have an account</p>
                        <div className="sign-up-wrapper">
                            <Link to={'/'}>
                                <button>Login</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Signup
