import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function RevenueGraph() {
    const [revenueData, setRevenueData] = useState([]);
    const [labels, setLabels] = useState([]);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    useEffect(() => {
        async function fetchReceiptsHistory() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-receipts-history-list/`, config);

            // Process the data
            const revenue = data.map((receipt) => ({
                date: receipt.transaction_date,
                amount: receipt.payment_amount,
            }));

            // Group by date
            const groupedData = revenue.reduce((acc, curr) => {
                const date = curr.date;
                if (!acc[date]) {
                    acc[date] = 0;
                }
                acc[date] += curr.amount;
                return acc;
            }, {});

            // Prepare labels and revenue data for the chart
            const sortedDates = Object.keys(groupedData).sort();
            const sortedRevenue = sortedDates.map((date) => groupedData[date]);

            setLabels(sortedDates);
            setRevenueData(sortedRevenue);
        }

        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };

            fetchReceiptsHistory()
        }
        
    }, [config]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Revenue',
                data: revenueData,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Revenue Over Time',
            },
        },
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <h1>Revenue Graph</h1>
                    <Line data={data} options={options} />
                    <br></br>
                </div>
            </div>
        </div>
    );
}

export default RevenueGraph;
