import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

function CustomerPurchaseDetails() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { clientId } = useParams();  // Assuming clientId is passed in the URL
    const [customerDetails, setCustomerDetails] = useState(null);

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let config = {}

    useEffect(() => {
        async function fetchCustomerDetails() {
            try {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-customer-purchase-details/${clientId}/`, config);
                setCustomerDetails(data);
            } catch (error) {
                console.error('Error fetching customer details:', error);
            }
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchCustomerDetails()
        }
    }, []);

    if (!customerDetails) {
        return <p>Error while loading customer details</p>;
    }

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <h1>Customer Purchase Details</h1>
                    <br></br>
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>{customerDetails.customer_name}</Card.Title>
                            <Card.Text>
                                <strong>Age:</strong> {customerDetails.customer_age}<br />
                                <strong>Locality:</strong> {customerDetails.customer_locality}<br />
                                <strong>Gender:</strong> {customerDetails.customer_gender}<br />
                                <strong>Average Transaction Size:</strong> ${customerDetails.average_transaction_size.toFixed(2)}<br />
                                <strong>Retention Period:</strong> {customerDetails.retention_period} days<br />
                                <strong>Number of Transactions:</strong> {customerDetails.number_of_transactions}<br />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default CustomerPurchaseDetails;
