import React, { useState, useEffect } from 'react'

import { Container, Form, Button } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import { useParams } from 'react-router-dom';

import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

function AddEditClients({ match }) {

    const { id } = useParams();
    const [name, setName] = useState('')
    const [age, setAge] = useState('')
    const [dob, setDOB] = useState('')
    const [address, setAddress] = useState('')
    const [gender, setGender] = useState('')
    const [businesses, setBusinesses] = useState('')

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    const [businessesList, setBusinessesList] = useState([])

    useEffect(() => {
        if (id) {
            async function fetchClient() {
                const { data } = await axios.get(`${apiUrl}/api/merchant/get-client/${id}/`,
                    config)
                setName(data.name)
                setAge(data.age)
                setDOB(data.DOB)
                setAddress(data.address)
                setGender(data.gender)
                setBusinesses(data.businesses.id)
            }

            if (userInfo) {
                config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.access}`,
                    },
                };

                fetchClient()
            }
        }

        async function fetchBusinessesList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-business-list/`,
                config)
            setBusinessesList(data)
        }

        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };

            fetchBusinessesList()
        }

    }, [])


    const handleSelectChange = (e) => {
        setBusinesses(e.target.value);
    };


    const submitHandler = async (e) => {

        e.preventDefault()

        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        if (id) {
            try {
                const { data } = await axios.post(
                    `${apiUrl}/api/merchant/update-client/${id}/`,
                    { 'name': name, 'age': age, 'dob': dob, 'address': address, 'gender': gender, 'businesses': businesses },
                    config
                );
                navigate('/clients/')
            } catch (error) {
                console.error('Error updating client:', error);
            }
        } else {
            try {
                const { data } = await axios.post(
                    `${apiUrl}/api/merchant/add-client/`,
                    { 'name': name, 'age': age, 'dob': dob, 'address': address, 'gender': gender, 'businesses': businesses },
                    config
                );
                navigate('/clients/')
            } catch (error) {
                console.error('Error adding client:', error);
            }
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <div className='page-title-and-bnt'>
                        {id ?
                            <h1>Edit Client</h1>
                            :
                            <h1>Add Client</h1>
                        }
                    </div>
                    <br></br>

                    <Form onSubmit={submitHandler}>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Age'
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Control
                            required
                            type='date'
                            placeholder='DOB'
                            value={dob}
                            onChange={(e) => setDOB(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Address'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Gender'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                        ></Form.Control>
                        <br></br>
                        <Form.Select
                            required
                            value={businesses}
                            onChange={handleSelectChange}
                        >
                            <option value="" disabled>Select a business</option>
                            {businessesList.map((businessObj) => (
                                <option value={businessObj.id}>{businessObj.business_name}</option>
                            ))}
                        </Form.Select>
                        <br></br>
                        <Button type='submit' variant='success'>Submit</Button>
                    </Form>

                </div>
            </div>
        </div>
    )
}

export default AddEditClients
