import React, { useState, useEffect } from 'react'

import Table from 'react-bootstrap/Table';

import { Container } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import Button from 'react-bootstrap/Button';

import { LinkContainer } from 'react-router-bootstrap';

function Businesses() {

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    const [businessesList, setBusinessesList] = useState([])

    useEffect(() => {

        async function fetchBusinessesList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-business-list/`,
                config)
            setBusinessesList(data)
        }

        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };

            fetchBusinessesList()

        }

    }, [])

    const deleteBusinessHandler = async (id) => {
        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        try {
            const { data } = await axios.delete(
                `${apiUrl}/api/merchant/delete-business/${id}/`,
                config
            );
            setBusinessesList(data);
        } catch (error) {
            console.error('Error activating user:', error);
        }

    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <div className='page-title-and-bnt'>
                        <h1>Businesses</h1>
                        {userInfo && userInfo.is_admin ?
                            <LinkContainer to={`/add-business/`}>
                                <Button variant="success">Add+</Button>
                            </LinkContainer>
                            :
                            <></>
                        }
                    </div>
                    <br></br>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Contact</th>
                                {userInfo && userInfo.is_admin ?
                                    <th>Options</th> :
                                    <></>
                                }
                            </tr>
                        </thead>
                        <tbody>

                            {businessesList.map(business => (
                                <tr>
                                    <td>{business.id}</td>
                                    <td>{business.business_name}</td>
                                    <td>{business.contact_info}</td>
                                    {userInfo.is_admin ?
                                        <td>
                                            <div className='users-list-btn'>
                                                <LinkContainer to={`/edit-business/${business.id}/`}>
                                                    <Button
                                                        variant="info"
                                                    >Update
                                                    </Button>
                                                </LinkContainer>
                                                <Button
                                                    variant="danger"
                                                    onClick={(e) => deleteBusinessHandler(business.id)}
                                                >Delete
                                                </Button>
                                            </div>
                                        </td> :
                                        <></>
                                    }
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Businesses
