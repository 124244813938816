import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import Loader from '../components/Loader'

import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';


import { login } from '../actions/userActions'

function Login() {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { error, loading, userInfo } = userLogin

    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo) {
            if (userInfo.is_admin) {
                navigate('/dashboard/')
            } else {
                navigate('/dashboard/')
            }

        }
    }, [userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(username, password))
    }

    return (
        <>
            {loading ? <Loader /> :
                <div className="custom-container" style={{ position: 'absolute', width: '100%', left: '0px' }} >
                    <form className="left" onSubmit={submitHandler}>
                        <h1>Login</h1>
                        {
                            error && <p className='error'>{error}</p>
                        }
                        <p>Don't have an Account? Create your Account</p>

                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <div className='password-field'>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <i className='uil uil-eye'></i> : <i className=' uil-eye-slash'></i>}
                            </button>
                        </div>
                        <Link to={'forgot-password/'}>
                            <a>Forgot Password?</a>
                        </Link>
                        <div className="submit-btn-wrapper">
                            <button type='submit'>Login</button>
                        </div>

                        <Link to={'sign-up/'}>
                            <a className='other-page-link'>Sign up</a>
                        </Link>

                        <div className="social-login">
                            <p>Or sign up with</p>
                            <div className="social-login-btns">
                                <button className="social-button fb">f</button>
                                <button className="social-button email">@</button>
                                <button className="social-button twitter">t</button>
                            </div>
                        </div>
                    </form>
                    <div className="right">
                        <h1>Welcome Back!</h1>
                        <p>Simply create your account by clicking the signup button!</p>
                        <div className="sign-up-wrapper">
                            <Link to={'sign-up/'}>
                                <button>Sign Up</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Login
