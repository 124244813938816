import React, { useState, useEffect } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ReceiptsHistoryList() {
    const [receiptsList, setReceiptsList] = useState([]);
    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    useEffect(() => {
        async function fetchReceiptsList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-receipts-history-list/`, config);
            setReceiptsList(data);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchReceiptsList()
        }
    }, [config]);

    const deleteReceiptHistoryHandler = async (id) => {

        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };

        try {
            await axios.delete(`${apiUrl}/api/merchant/delete-receipt-history/${id}/`, config);
            setReceiptsList(prevList => prevList.filter(receipt => receipt.id !== id));
        } catch (error) {
            console.error('Error deleting receipt history:', error);
        }
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="page-title-and-bnt">
                        <h1>Receipts History</h1>
                        {userInfo && userInfo.is_admin && (
                            <LinkContainer to="/add-receipt-history">
                                <Button variant="success">Add Receipt</Button>
                            </LinkContainer>
                        )}
                    </div>
                    <br />
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Account Number</th>
                                {userInfo && userInfo.is_admin && (
                                    <th>Company</th>
                                )}
                                <th>Transaction Date</th>
                                <th>Payment Amount</th>
                                <th>Payment Type</th>
                                <th>Plan</th>
                                {userInfo && userInfo.is_admin && <th>Options</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {receiptsList.map((receipt) => (
                                <tr key={receipt.id}>
                                    <td>{receipt.id}</td>
                                    <td>{receipt.account_number}</td>
                                    {userInfo && userInfo.is_admin && (
                                        <td>{receipt.company}</td>
                                    )}
                                    <td>{receipt.transaction_date}</td>
                                    <td>{receipt.payment_amount}</td>
                                    <td>{receipt.payment_type}</td>
                                    <td>{receipt.plan}</td>
                                    {userInfo && userInfo.is_admin && (
                                        <td>
                                            <div className='users-list-btn'>
                                                <LinkContainer to={`/edit-receipt-history/${receipt.id}`}>
                                                    <Button variant="info">Edit</Button>
                                                </LinkContainer>
                                                <Button variant="danger" onClick={() => deleteReceiptHistoryHandler(receipt.id)}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default ReceiptsHistoryList;
