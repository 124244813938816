import React, { useState, useEffect } from 'react'

import Table from 'react-bootstrap/Table';

import { Container } from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import Button from 'react-bootstrap/Button';

import { LinkContainer } from 'react-router-bootstrap';

function Clients() {

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    const [clientList, setClientList] = useState([])

    useEffect(() => {

        async function fetchClientList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-client-list/`,
                config)
            setClientList(data)
        }

        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };

            fetchClientList()
        }

    }, [])


    const deleteClientHandler = async (id) => {
        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        try {
            const { data } = await axios.delete(
                `${apiUrl}/api/merchant/delete-client/${id}/`,
                config
            );
            setClientList(data);
        } catch (error) {
            console.error('Error activating user:', error);
        }

    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <div className='page-title-and-bnt'>
                        <h1>Clients</h1>
                        {userInfo && userInfo.is_admin ?
                            <LinkContainer to={`/add-client/`}>
                                <Button variant="success">Add+</Button>
                            </LinkContainer>
                            :
                            <></>
                        }
                    </div>
                    <br></br>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Age</th>
                                <th>DOB</th>
                                <th>Address</th>
                                <th>Gender</th>
                                <th>Business</th>
                                {userInfo && userInfo.is_admin ?
                                    <>
                                        <th>Update</th>
                                        <th>Delete</th>
                                        <th>Purchase Details</th>
                                    </>
                                    :
                                    <></>
                                }
                            </tr>
                        </thead>
                        <tbody>

                            {clientList.map(client => (
                                <tr>
                                    <td>{client.id}</td>
                                    <td>{client.name}</td>
                                    <td>{client.age}</td>
                                    <td>{client.DOB}</td>
                                    <td>{client.address}</td>
                                    <td>{client.gender}</td>
                                    <td>{client.businesses.business_name}</td>
                                    {userInfo && userInfo.is_admin ?
                                        <>
                                            <td>
                                                <LinkContainer to={`/edit-client/${client.id}/`}>
                                                    <Button
                                                        variant="info"
                                                    >Update
                                                    </Button>
                                                </LinkContainer>
                                            </td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    onClick={(e) => deleteClientHandler(client.id)}
                                                >Delete
                                                </Button>
                                            </td>
                                            <td>
                                                <LinkContainer to={`/customer-details/${client.id}/`}>
                                                    <Button
                                                        variant="primary"
                                                    >Details
                                                    </Button>
                                                </LinkContainer>
                                            </td>
                                        </>
                                        :
                                        <></>
                                    }
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Clients
