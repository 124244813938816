import React, { useState, useEffect } from 'react'
import Login from "./pages/login";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgotPassword.js";
import ResetPassword from "./pages/resetPassword.js";

// New theme
import Navbar from "./components/navbar.js";

import SideBar from "./components/sideBar.js";

import Dashboard from "./pages/dashboard.js";

// Admin
import NewUsers from "./pages/admin/newUsers";
import UsersList from "./pages/admin/usersList";

import RepeatPurchaseRateGraph from "./pages/admin/graphs/repeatPurchaseRateGraph.js";

import CustomerChurnRateGraph from "./pages/admin/graphs/customerChurnRateGraph.js";

import ItemsSoldDetails from "./pages/admin/graphs/itemsSoldDetails.js";

import CustomerPurchaseDetails from "./pages/admin/graphs/customerPurchaseDetails.js";

import NPSChart from "./pages/admin/graphs/NPS.js";

import IncreasedMarginChart from "./pages/admin/graphs/increasedMarginChart.js";

// Merchant
import Businesses from "./pages/merchant/business/businesses";
import AddEditBusiness from "./pages/merchant/business/addEditBusiness";
import Clients from "./pages/merchant/clients/clients";
import AddEditClients from "./pages/merchant/clients/addEditClients";
import Items from "./pages/merchant/items/items.js";
import AddEditItems from "./pages/merchant/items/addEditItems.js";
import PurchaseHistory from "./pages/merchant/purchaseHistory/purchaseHistory.js";
import AddEditPurchaseHistory from "./pages/merchant/purchaseHistory/addEditPurchaseHistory.js";
import ItemsPurchased from "./pages/merchant/items/itemsPurchased.js";
import AddEditItemsPurchased from "./pages/merchant/items/addEditItemsPurchased.js";
import ReceiptsHistoryList from "./pages/merchant/receiptsHistory/receiptsHistory.js";
import AddEditReceiptsHistory from "./pages/merchant/receiptsHistory/addEditReceiptsHistory.js";
import RevenueGraph from "./pages/merchant/graphs/revenueGraph.js";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

  const [isSidebarVisible, setSidebarVisible] = useState(true)


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true); // Show sidebar by default on larger screens
      }
    };

    // Initial call
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="wrapper">
      <Router>
        <Routes>
          <Route path='/' element={<Login />} exact />
          <Route path='/sign-up/' element={<Signup />} />
          <Route path="/forgot-password/" element={<ForgotPassword />} />
          <Route path="/reset-password/" element={<ResetPassword />} />

          <Route path='/dashboard/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <Dashboard />
            </>
          } />

          <Route path='/inactive-users/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <NewUsers />
            </>
          } />

          <Route path='/users-list/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <UsersList />
            </>
          } />

          <Route path='/businesses/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <Businesses />
            </>
          } />

          <Route path='/add-business/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditBusiness />
            </>
          } />

          <Route path='/edit-business/:id/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditBusiness />
            </>
          } />

          <Route path='/clients/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <Clients />
            </>
          } />

          <Route path='/add-client/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditClients />
            </>
          } />

          <Route path='/edit-client/:id/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditClients />
            </>
          } />


          <Route path='/items/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <Items />
            </>
          } />

          <Route path='/add-item/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditItems />
            </>
          } />

          <Route path='/edit-item/:id/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditItems />
            </>
          } />


          <Route path='/purchase-history/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <PurchaseHistory />
            </>
          } />

          <Route path='/add-purchase-history/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditPurchaseHistory />
            </>
          } />

          <Route path='/edit-purchase-history/:id/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditPurchaseHistory />
            </>
          } />

          <Route path='/items-purchased/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <ItemsPurchased />
            </>
          } />

          <Route path='/add-item-purchased/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditItemsPurchased />
            </>
          } />

          <Route path='/edit-item-purchased/:id/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditItemsPurchased />
            </>
          } />

          <Route path='/receipts-history/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <ReceiptsHistoryList />
            </>
          } />

          <Route path='/add-receipt-history/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditReceiptsHistory />
            </>
          } />

          <Route path='/edit-receipt-history/:id/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <AddEditReceiptsHistory />
            </>
          } />


          <Route path='/revenue-graph/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <RevenueGraph />
            </>
          } />


          <Route path='/repeat-purchase-rate-graph/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <RepeatPurchaseRateGraph />
            </>
          } />

          <Route path='/customer-churn-rate-graph/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <CustomerChurnRateGraph />
            </>
          } />

          <Route path='/items-sold-details/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <ItemsSoldDetails />
            </>
          } />

          <Route path='/customer-details/:clientId/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <CustomerPurchaseDetails />
            </>
          } />

          <Route path='/NPS/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <NPSChart />
            </>
          } />

          <Route path='/increasedMarginChart/' element={
            <>
              <Navbar setSidebarVisible={setSidebarVisible} isSidebarVisible={isSidebarVisible} />
              <SideBar isSidebarVisible={isSidebarVisible} />
              <IncreasedMarginChart />
            </>
          } />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
