import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ItemsSoldDetails() {
    const [mostSoldItem, setMostSoldItem] = useState({});
    const [leastSoldItem, setLeastSoldItem] = useState({});
    const [mostBuyingAgeGroup, setMostBuyingAgeGroup] = useState({});
    const [purchaseTimings, setPurchaseTimings] = useState([]);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    useEffect(() => {
        async function fetchItemsSoldDetails() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-items-sold-details/`, config);
            setMostSoldItem(data.most_sold_item);
            setLeastSoldItem(data.least_sold_item);
            setMostBuyingAgeGroup(data.most_buying_age_group);
            setPurchaseTimings(data.purchase_timings);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchItemsSoldDetails()
        }
    }, []);

    // Data for the bar chart (Timings of Purchase by Hour)
    const purchaseTimingData = {
        labels: purchaseTimings.map((timing) => timing.hour),
        datasets: [
            {
                label: 'Purchases by Hour',
                data: purchaseTimings.map((timing) => timing.total_purchases),
                backgroundColor: '#36A2EB',
            },
        ],
    };

    const purchaseTimingOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Purchases by Hour of the Day',
            },
        },
    };

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <h1>Items Sold Details</h1>
                    <br></br>
                    <Row>
                        <Col md={4}>
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Title>Most Sold Item</Card.Title>
                                    <Card.Text>
                                        {mostSoldItem ? `${mostSoldItem.item_name} (Quantity: ${mostSoldItem.total_sold})` : 'Loading...'}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Title>Least Sold Item</Card.Title>
                                    <Card.Text>
                                        {leastSoldItem ? `${leastSoldItem.item_name} (Quantity: ${leastSoldItem.total_sold})` : 'Loading...'}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Title>Most Buying Age Group</Card.Title>
                                    <Card.Text>
                                        {mostBuyingAgeGroup ? `${
                                            mostBuyingAgeGroup.age_group == 0 ?
                                            'under 18' :
                                            mostBuyingAgeGroup.age_group == 1 ?
                                            '18-25 years' :
                                            mostBuyingAgeGroup.age_group == 2 ?
                                            '25-35 years' :
                                            mostBuyingAgeGroup.age_group == 3 ?
                                            '35-45 years' :
                                            mostBuyingAgeGroup.age_group == 4 ?
                                            '45-55 years' :
                                            mostBuyingAgeGroup.age_group == 5 ?
                                            '55-65 years' :
                                            '65 +'
                                            } (Total Purchases: ${mostBuyingAgeGroup.total_purchases})` : 'Loading...'}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <div style={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Bar data={purchaseTimingData} options={purchaseTimingOptions} height={300} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default ItemsSoldDetails;
