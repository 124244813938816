import React from 'react';
import ReactDOM from 'react-dom/client';

// Redux
import { Provider } from 'react-redux'
import store from './store';

// custom css
import './css/base.css'
import './css/auth.css'
import './css/head-nav.css'

import './css/custom-changes.css'


import './css/app-modern.min.css'
import './css/icons.min.css'

// import './bootstrap.min.css'

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
    </Provider>
);

reportWebVitals();
