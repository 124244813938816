import React from 'react';
import Chart from 'react-apexcharts';

function NPSChart() {
    // Sample Data for NPS
    const promoters = 100;
    const passives = 20;
    const detractors = 10;
    const totalRespondents = promoters + passives + detractors;
    const npsScore = ((promoters - detractors) / totalRespondents) * 100;

    // ApexChart configuration for NPS Score
    const chartOptions = {
        chart: {
            id: 'nps-chart',
            type: 'bar',
            height: 550,
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: ['Promoters', 'Passives', 'Detractors'],
            title: {
                text: 'Survey Respondents',
            },
        },
        yaxis: {
            title: {
                text: 'Number of Respondents',
            },
        },
        title: {
            text: `Net Promoter Score: ${npsScore.toFixed(2)}`,
            align: 'center',
            style: {
                fontSize: '20px',
            },
        },
        colors: ['#00E396', '#FEB019', '#FF4560'],
    };

    const chartSeries = [
        {
            name: 'Respondents',
            data: [promoters, passives, detractors],
        },
    ];

    return (
        <div className="content-page" >
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <div style={{ padding: '10px' }}>
                        <h2>Net Promoter Score (NPS)</h2>
                        <br></br>
                        <Chart options={chartOptions} series={chartSeries} type="bar" height={350} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NPSChart;
