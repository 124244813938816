import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function CustomerChurnRateGraph() {
    const [churnRate, setChurnRate] = useState(0);
    const [churnedClients, setChurnedClients] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {}

    useEffect(() => {
        async function fetchChurnRate() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-customer-churn-rate/`, config);
            setChurnRate(data.churn_rate);
            setChurnedClients(data.churned_clients);
            setTotalClients(data.total_clients);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchChurnRate()
        }
    }, [config]);

    const data = {
        labels: ['Churn Rate', 'Retention Rate'],
        datasets: [
            {
                label: 'Percentage',
                data: [churnRate, 100 - churnRate],
                backgroundColor: ['#FF6384', '#36A2EB'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB'],
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Customer Churn Rate',
            },
        },
    };

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <br></br>
                    <h1>Customer Churn Rate</h1>
                    <br></br>
                    <div style={{ height: '48vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Bar data={data} options={options} height={130} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerChurnRateGraph;
