import React, { useState, useEffect } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function PurchaseHistory() {
    const [purchaseHistoryList, setPurchaseHistoryList] = useState([]);
    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    let config = {};

    useEffect(() => {
        async function fetchPurchaseHistoryList() {
            const { data } = await axios.get(`${apiUrl}/api/merchant/get-purchase-history-list/`, config);
            setPurchaseHistoryList(data);
        }
        if (userInfo) {
            config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.access}`,
                },
            };
            fetchPurchaseHistoryList();
        }
    }, [userInfo]);

    const deletePurchaseHistoryHandler = async (id) => {
        config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        try {
            await axios.delete(`${apiUrl}/api/merchant/delete-purchase-history/${id}/`, config);
            setPurchaseHistoryList(prevList => prevList.filter(item => item.id !== id));
        } catch (error) {
            console.error('Error deleting purchase history:', error);
        }
    };

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="page-title-and-bnt">
                        <h1>Purchase History</h1>
                        {userInfo && userInfo.is_admin && (
                            <LinkContainer to="/add-purchase-history/">
                                <Button variant="success">Add Purchase</Button>
                            </LinkContainer>
                        )}
                    </div>
                    <br />
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Client</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Total Amount</th>
                                {userInfo && userInfo.is_admin && <th>Options</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseHistoryList.map((history) => {
                                // Extract and format the date and time
                                const datePart = history.date.split('T')[0];
                                const timePart = history.date.split('T')[1].split(':');
                                const formattedTime = `${timePart[0]}:${timePart[1]}`; // Format as HH:MM

                                return (
                                    <tr key={history.id}>
                                        <td>{history.id}</td>
                                        <td>{history.client_id.name}</td>
                                        <td>{datePart}</td>
                                        <td>{formattedTime}</td>
                                        <td>{history.total_amount}</td>
                                        {userInfo && userInfo.is_admin && (
                                            <td>
                                                <div className='users-list-btn'>
                                                    <LinkContainer to={`/edit-purchase-history/${history.id}/`}>
                                                        <Button variant="info">Edit</Button>
                                                    </LinkContainer>
                                                    <Button variant="danger" onClick={() => deletePurchaseHistoryHandler(history.id)}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default PurchaseHistory;
